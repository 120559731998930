import { useI18n } from 'vue-i18n'
export default {
  setup() {
    const { t } = useI18n()
    const recurringTypes = [
      {
        value: 'scheduled',
        label: t('common.orders.recurring_type.scheduled')
      },
      {
        value: 'unscheduled',
        label: t('common.orders.recurring_type.unscheduled')
      },
      {
        value: 'oneclick',
        label: t('common.orders.recurring_type.oneclick')
      }
    ]
    const paymentMethodCodes = [
      {
        value: 'alipay',
        label: t('common.orders.payment_code.alipay')
      },
      {
        value: 'applepay',
        label: t('common.orders.payment_code.applepay')
      },
      {
        value: 'bancontact',
        label: t('common.orders.payment_code.bancontact')
      },
      {
        value: 'bank_transfer',
        label: t('common.orders.payment_code.bank_transfer')
      },
      {
        value: 'billpay_installment',
        label: t('common.orders.payment_code.billpay_installment')
      },
      {
        value: 'billpay_invoice',
        label: t('common.orders.payment_code.billpay_invoice')
      },
      {
        value: 'credit_card',
        label: t('common.orders.payment_code.credit_card')
      },
      {
        value: 'easycredit_installment',
        label: t('common.orders.payment_code.debit_card')
      },
      {
        value: 'eps',
        label: t('common.orders.payment_code.eps')
      },
      {
        value: 'fleetcard',
        label: t('common.orders.payment_code.fleetcard')
      },
      {
        value: 'girocard',
        label: t('common.orders.payment_code.girocard')
      },
      {
        value: 'giropay',
        label: t('common.orders.payment_code.giropay')
      },
      {
        value: 'googlepay',
        label: t('common.orders.payment_code.googlepay')
      },
      {
        value: 'ideal',
        label: t('common.orders.payment_code.ideal')
      },
      {
        value: 'installment',
        label: t('common.orders.payment_code.installment')
      },
      {
        value: 'invoice',
        label: t('common.orders.payment_code.invoice')
      },
      {
        value: 'klarna',
        label: t('common.orders.payment_code.klarna')
      },
      {
        value: 'klarna_installment',
        label: t('common.orders.payment_code.klarna_installment')
      },
      {
        value: 'klarna_invoice',
        label: t('common.orders.payment_code.klarna_invoice')
      },
      {
        value: 'loyalty',
        label: t('common.orders.payment_code.loyalty')
      },
      {
        value: 'monthly_invoice',
        label: t('common.orders.payment_code.monthly_invoice')
      },
      {
        value: 'paypal',
        label: t('common.orders.payment_code.paypal')
      },
      {
        value: 'paypal_express',
        label: t('common.orders.payment_code.paypal_express')
      },
      {
        value: 'paysafecard',
        label: t('common.orders.payment_code.paysafecard')
      },
      {
        value: 'paysafecash',
        label: t('common.orders.payment_code.paysafecash')
      },
      {
        value: 'post_finance_card',
        label: t('common.orders.payment_code.post_finance_card')
      },
      {
        value: 'post_finance_efinance',
        label: t('common.orders.payment_code.post_finance_efinance')
      },
      {
        value: 'prepayment',
        label: t('common.orders.payment_code.prepayment')
      },
      {
        value: 'przelewy24',
        label: t('common.orders.payment_code.przelewy24')
      },
      {
        value: 'santander_installment',
        label: t('common.orders.payment_code.santander_installment')
      },
      {
        value: 'santander_purchase_on_account',
        label: t('common.orders.payment_code.santander_purchase_on_account')
      },
      {
        value: 'sepa_direct_debit',
        label: t('common.orders.payment_code.sepa_direct_debit'),
        icon: 'sepa'
      },
      {
        value: 'sofort',
        label: t('common.orders.payment_code.sofort')
      },
      {
        value: 'wechatpay',
        label: t('common.orders.payment_code.wechatpay')
      },
      {
        value: 'unknown',
        label: t('common.orders.payment_code.unknown')
      }
    ]
    const paymentMethodBrands = [
      {
        value: 'amex',
        label: t('common.orders.brands.amex')
      },
      {
        value: 'avs_voucher_card',
        label: t('common.orders.brands.avs'),
        icon: 'avs'
      },
      {
        value: 'bancontact',
        label: t('common.orders.brands.bancontact')
      },
      {
        value: 'bsw',
        label: t('common.orders.brands.bsw')
      },
      {
        value: 'cartasi',
        label: t('common.orders.brands.cartasi')
      },
      {
        value: 'cartebancaire',
        label: t('common.orders.brands.cartebancaire')
      },
      {
        value: 'cartebleue',
        label: t('common.orders.brands.cartebleue')
      },
      {
        value: 'cup',
        label: t('common.orders.brands.cup')
      },
      {
        value: 'dankort',
        label: t('common.orders.brands.dankort')
      },
      {
        value: 'diners',
        label: t('common.orders.brands.diners')
      },
      {
        value: 'discover',
        label: t('common.orders.brands.discover')
      },
      {
        value: 'dkv',
        label: t('common.orders.brands.dkv')
      },
      {
        value: 'euro6000',
        label: t('common.orders.brands.euro6000')
      },
      {
        value: 'four_b',
        label: t('common.orders.brands.four_b')
      },
      {
        value: 'girocard',
        label: t('common.orders.brands.girocard')
      },
      {
        value: 'jcb',
        label: t('common.orders.brands.jcb')
      },
      {
        value: 'maestro',
        label: t('common.orders.brands.maestro'),
        icon: 'mastercard'
      },
      {
        value: 'mastercard',
        label: t('common.orders.brands.mastercard')
      },
      {
        value: 'postepay',
        label: t('common.orders.brands.postepay')
      },
      {
        value: 'servired',
        label: t('common.orders.brands.servired')
      },
      {
        value: 'taxfree',
        label: t('common.orders.brands.taxfree'),
        icon: 'other'
      },
      {
        value: 'uta',
        label: t('common.orders.brands.uta')
      },
      {
        value: 'visa',
        label: t('common.orders.brands.visa')
      },
      {
        value: 'visa_debit',
        label: t('common.orders.brands.visa_debit'),
        icon: 'visa'
      },
      {
        value: 'visa_electron',
        label: t('common.orders.brands.visa_electron'),
        icon: 'visa'
      },
      {
        value: 'vpay',
        label: t('common.orders.brands.vpay'),
        icon: 'visa'
      },
      {
        value: 'unknown',
        label: t('common.orders.brands.unknown'),
        icon: 'other'
      },
      {
        value: 'undefined',
        label: t('common.orders.brands.undefined'),
        icon: 'other'
      }
    ]
    const transactionStates = [
      {
        value: 'success',
        label: t('common.orders.transaction.statuses.success')
      },
      {
        value: 'waiting',
        label: t('common.orders.transaction.statuses.waiting')
      },
      {
        value: 'rejected',
        label: t('common.orders.transaction.statuses.rejected')
      },
      {
        value: 'failed',
        label: t('common.orders.transaction.statuses.failed')
      }
    ]
    const statuses = [
      {
        value: 'completed',
        label: t('common.orders.statuses.completed')
      },
      {
        value: 'not_completed',
        label: t('common.orders.statuses.not_completed')
      },
      {
        value: 'collected',
        label: t('common.orders.basket.statuses.collected')
      },
      {
        value: 'not_collected',
        label: t('common.orders.basket.statuses.not_collected')
      },
      {
        value: 'successful',
        label: t('common.orders.transaction.statuses.successful')
      },
      {
        value: 'unsuccessful',
        label: t('common.orders.transaction.statuses.unsuccessful')
      },
      {
        value: 'on_work',
        label: t('common.orders.transaction.statuses.on_work')
      },
      {
        value: 'new',
        label: t('common.orders.transaction.statuses.new')
      },
      {
        value: 'fail_collect',
        label: t('common.orders.transaction.statuses.fail_collect')
      },
      {
        value: 'ACK',
        label: t('common.transactions.statuses.ack')
      }
    ]
    const origins = [
      {
        value: 'online',
        label: t('common.orders.origins.online')
      },
      {
        value: 'instore',
        label: t('common.orders.origins.instore')
      },
      {
        value: 'txn-core',
        label: t('common.orders.origins.txn-core')
      }
    ]
    const basketItemStatuses = [
      {
        value: 'collected',
        label: t('common.orders.basket_item_status.collected')
      }
    ]
    const disputeReasons = [
      {
        value: 'general',
        label: t('common.orders.dispute_reasons.general')
      }
    ]
    const disputeTypes = [
      {
        value: 'first_chargeback',
        label: t('common.orders.dispute_types.first_chargeback')
      }
    ]
    const disputeStatuses = [
      {
        value: 'under_review',
        label: t('common.orders.dispute_statuses.under_review')
      }
    ]
    const transactionTypes = [
      {
        value: 'authorization',
        label: t('common.orders.transaction.types.authorization')
      },
      {
        value: 'capture',
        label: t('common.orders.transaction.types.capture')
      },
      {
        value: 'change_schedule',
        label: t('common.orders.transaction.types.change_schedule')
      },
      {
        value: 'chargeback',
        label: t('common.orders.transaction.types.chargeback')
      },
      {
        value: 'chargeback_notification',
        label: t('common.orders.transaction.types.chargeback_notification')
      },
      {
        value: 'chargeback_reversal',
        label: t('common.orders.transaction.types.chargeback_reversal')
      },
      {
        value: 'confirmation',
        label: t('common.orders.transaction.types.confirmation')
      },
      {
        value: 'credit',
        label: t('common.orders.transaction.types.credit')
      },
      {
        value: 'cutover',
        label: t('common.orders.transaction.types.cutover')
      },
      {
        value: 'dcc_inquiry',
        label: t('common.orders.transaction.types.dcc_inquiry')
      },
      {
        value: 'debit',
        label: t('common.orders.transaction.types.debit')
      },
      {
        value: 'debit_reversal',
        label: t('common.orders.transaction.types.debit_reversal')
      },
      {
        value: 'deregistration',
        label: t('common.orders.transaction.types.deregistration')
      },
      {
        value: 'diagnosis',
        label: t('common.orders.transaction.types.diagnosis')
      },
      {
        value: 'end_schedule',
        label: t('common.orders.transaction.types.end_schedule')
      },
      {
        value: 'file_transfer',
        label: t('common.orders.transaction.types.file_transfer')
      },
      {
        value: 'finalize',
        label: t('common.orders.transaction.types.finalize')
      },
      {
        value: 'initialize',
        label: t('common.orders.transaction.types.initialize')
      },
      {
        value: 'installment_inquiry',
        label: t('common.orders.transaction.types.installment_inquiry')
      },
      {
        value: 'notification',
        label: t('common.orders.transaction.types.notification')
      },
      {
        value: 'reauthorization',
        label: t('common.orders.transaction.types.reauthorization')
      },
      {
        value: 'receipt',
        label: t('common.orders.transaction.types.receipt')
      },
      {
        value: 'refund',
        label: t('common.orders.transaction.types.refund')
      },
      {
        value: 'registration',
        label: t('common.orders.transaction.types.registration')
      },
      {
        value: 'reversal',
        label: t('common.orders.transaction.types.reversal')
      },
      {
        value: 'risk_check',
        label: t('common.orders.transaction.types.risk_check')
      },
      {
        value: 'schedule',
        label: t('common.orders.transaction.types.schedule')
      },
      {
        value: 'taxfree_inquiry',
        label: t('common.orders.transaction.types.taxfree_inquiry'),
        icon: 'other'
      },
      {
        value: 'three_ds_authentication',
        label: t('common.orders.transaction.types.three_ds_authentication')
      },
      {
        value: 'update_registration',
        label: t('common.orders.transaction.types.update_registration')
      },
      {
        value: 'undefined',
        label: t('common.orders.transaction.types.undefined'),
        icon: 'other'
      },
      {
        value: 'unknown',
        label: t('common.orders.transaction.types.unknown'),
        icon: 'other'
      }
    ]
    const transactionResult = [
      {
        value: 'ACK',
        label: t('common.transactions.statuses.ack')
      },
      {
        value: 'NOK',
        label: t('common.transactions.statuses.nok')
      }
    ]
    const processingStatusDescriptions = [
      {
        value: 'new',
        label: t('common.transactions.processing_statuses.new')
      },
      {
        value: 'rejected_bank',
        label: t('common.transactions.processing_statuses.rejected_bank')
      },
      {
        value: 'rejected_validation',
        label: t('common.transactions.processing_statuses.rejected_validation')
      },
      {
        value: 'rejected_risk',
        label: t('common.transactions.processing_statuses.rejected_risk')
      }
    ]
    const paymentRecurrenceContracts = [
      {
        value: 'oneclick',
        label: t('common.transactions.recurrence_contracts.oneclick')
      },
      {
        value: 'recurring',
        label: t('common.transactions.recurrence_contracts.recurring')
      },
      {
        value: 'installment',
        label: t('common.transactions.recurrence_contracts.installment')
      },
      {
        value: 'ucof',
        label: t('common.transactions.recurrence_contracts.ucof')
      },
      {
        value: 'unknown',
        label: t('common.transactions.recurrence_contracts.unknown')
      }
    ]
    const paymentRecurrenceSequences = [
      {
        value: 'initial',
        label: t('common.transactions.recurrence_sequences.initial')
      },
      {
        value: 'subsequent',
        label: t('common.transactions.recurrence_sequences.subsequent')
      },
      {
        value: 'final',
        label: t('common.transactions.recurrence_sequences.final')
      },
      {
        value: 'unknown',
        label: t('common.transactions.recurrence_sequences.unknown')
      }
    ]
    const paymentThreeDSVersions = [
      {
        value: 'v_1_0_2',
        label: t('common.transactions.three_ds_versions.v_1_0_2')
      },
      {
        value: 'v_2_0_0',
        label: t('common.transactions.three_ds_versions.v_2_0_0')
      },
      {
        value: 'v_2_1_0',
        label: t('common.transactions.three_ds_versions.v_2_1_0')
      },
      {
        value: 'v_2_2_0',
        label: t('common.transactions.three_ds_versions.v_2_2_0')
      },
      {
        value: 'unknown',
        label: t('common.transactions.three_ds_versions.unknown')
      }
    ]

    const paymentThreeDSStatuses = [
      {
        value: 'y',
        label: t('common.transactions.three_ds_statuses.y')
      },
      {
        value: 'n',
        label: t('common.transactions.three_ds_statuses.n')
      },
      {
        value: 'u',
        label: t('common.transactions.three_ds_statuses.u')
      },
      {
        value: 'a',
        label: t('common.transactions.three_ds_statuses.a')
      },
      {
        value: 'r',
        label: t('common.transactions.three_ds_statuses.r')
      },
      {
        value: 'unknown',
        label: t('common.transactions.three_ds_statuses.unknown')
      }
    ]
    const paymentThreeDSReasons = [
      { value: '_01', label: t('common.transactions.three_ds_reasons._01') },
      { value: '_02', label: t('common.transactions.three_ds_reasons._02') },
      { value: '_03', label: t('common.transactions.three_ds_reasons._03') },
      { value: '_04', label: t('common.transactions.three_ds_reasons._04') },
      { value: '_05', label: t('common.transactions.three_ds_reasons._05') },
      { value: '_06', label: t('common.transactions.three_ds_reasons._06') },
      { value: '_07', label: t('common.transactions.three_ds_reasons._07') },
      { value: '_08', label: t('common.transactions.three_ds_reasons._08') },
      { value: '_09', label: t('common.transactions.three_ds_reasons._09') },
      { value: '_10', label: t('common.transactions.three_ds_reasons._10') },
      { value: '_11', label: t('common.transactions.three_ds_reasons._11') },
      { value: '_12', label: t('common.transactions.three_ds_reasons._12') },
      { value: '_13', label: t('common.transactions.three_ds_reasons._13') },
      { value: '_14', label: t('common.transactions.three_ds_reasons._14') },
      { value: '_15', label: t('common.transactions.three_ds_reasons._15') },
      { value: '_16', label: t('common.transactions.three_ds_reasons._16') },
      { value: '_17', label: t('common.transactions.three_ds_reasons._17') },
      { value: '_18', label: t('common.transactions.three_ds_reasons._18') },
      { value: '_19', label: t('common.transactions.three_ds_reasons._19') },
      { value: '_20', label: t('common.transactions.three_ds_reasons._20') },
      { value: '_21', label: t('common.transactions.three_ds_reasons._21') },
      { value: '_80', label: t('common.transactions.three_ds_reasons._80') },
      { value: '_81', label: t('common.transactions.three_ds_reasons._81') },
      { value: '_82', label: t('common.transactions.three_ds_reasons._82') },
      { value: '_83', label: t('common.transactions.three_ds_reasons._83') },
      { value: '_84', label: t('common.transactions.three_ds_reasons._84') },
      { value: '_85', label: t('common.transactions.three_ds_reasons._85') },
      { value: '_86', label: t('common.transactions.three_ds_reasons._86') },
      { value: '_87', label: t('common.transactions.three_ds_reasons._87') },
      { value: '_88', label: t('common.transactions.three_ds_reasons._88') },
      {
        value: '_80_mastercard',
        label: t('common.transactions.three_ds_reasons._80_mastercard')
      },
      {
        value: '_80_visa',
        label: t('common.transactions.three_ds_reasons._80_visa')
      },
      {
        value: '_81_visa',
        label: t('common.transactions.three_ds_reasons._81_visa')
      },
      {
        value: '_82_visa',
        label: t('common.transactions.three_ds_reasons._82_visa')
      },
      {
        value: '_83_visa',
        label: t('common.transactions.three_ds_reasons._83_visa')
      },
      {
        value: '_84_visa',
        label: t('common.transactions.three_ds_reasons._84_visa')
      },
      {
        value: '_85_visa',
        label: t('common.transactions.three_ds_reasons._85_visa')
      },
      {
        value: '_86_visa',
        label: t('common.transactions.three_ds_reasons._86_visa')
      },
      {
        value: '_87_visa',
        label: t('common.transactions.three_ds_reasons._87_visa')
      },
      {
        value: '_88_visa',
        label: t('common.transactions.three_ds_reasons._88_visa')
      },
      {
        value: 'unknown',
        label: t('common.transactions.three_ds_reasons.unknown')
      }
    ]
    const paymentThreeDSIndicators = [
      { value: '_01', label: t('common.transactions.three_ds_indicators._01') },
      { value: '_02', label: t('common.transactions.three_ds_indicators._02') },
      { value: '_03', label: t('common.transactions.three_ds_indicators._03') },
      { value: '_04', label: t('common.transactions.three_ds_indicators._04') },
      {
        value: 'unknown',
        label: t('common.transactions.three_ds_indicators.unknown')
      }
    ]
    const paymentThreeDSExemptions = [
      {
        value: 'mit',
        label: t('common.transactions.three_ds_exemptionss.mit')
      },
      {
        value: 'tra',
        label: t('common.transactions.three_ds_exemptionss.tra')
      },
      {
        value: 'recurring',
        label: t('common.transactions.three_ds_exemptionss.recurring')
      },
      {
        value: 'lvp',
        label: t('common.transactions.three_ds_exemptionss.lvp')
      },
      {
        value: 'delegation',
        label: t('common.transactions.three_ds_exemptionss.delegation')
      },
      {
        value: 'unknown',
        label: t('common.transactions.three_ds_exemptionss.unknown')
      }
    ]
    const paymentThreeDSCommerceIndicators = [
      {
        value: '_01',
        label: t('common.transactions.three_ds_commerce_indicators._01')
      },
      {
        value: '_02',
        label: t('common.transactions.three_ds_commerce_indicators._02')
      },
      {
        value: '_05',
        label: t('common.transactions.three_ds_commerce_indicators._05')
      },
      {
        value: '_06',
        label: t('common.transactions.three_ds_commerce_indicators._06')
      },
      {
        value: '_07',
        label: t('common.transactions.three_ds_commerce_indicators._07')
      },
      {
        value: 'unknown',
        label: t('common.transactions.three_ds_commerce_indicators.unknown')
      }
    ]
    const paymentThreeDSCommerceLiabilities = [
      {
        value: 'merchant',
        label: t('common.transactions.three_ds_commerce_liabilities.merchant')
      },
      {
        value: 'issuer',
        label: t('common.transactions.three_ds_commerce_liabilities.issuer')
      },
      {
        value: 'unknown',
        label: t('common.transactions.three_ds_commerce_liabilities.unknown')
      }
    ]

    return {
      recurringTypes,
      paymentMethodCodes,
      paymentMethodBrands,
      transactionStates,
      statuses,
      origins,
      basketItemStatuses,
      disputeReasons,
      disputeTypes,
      disputeStatuses,
      transactionTypes,
      transactionResult,
      processingStatusDescriptions,
      paymentRecurrenceContracts,
      paymentRecurrenceSequences,
      paymentThreeDSVersions,
      paymentThreeDSStatuses,
      paymentThreeDSReasons,
      paymentThreeDSIndicators,
      paymentThreeDSExemptions,
      paymentThreeDSCommerceIndicators,
      paymentThreeDSCommerceLiabilities
    }
  }
}
