<template>
  <span
    class="ml-2 p-1 underline text-sm status-text"
    :class="{ success: isSuccess, warning: isWarning, error: isError }"
  >
    {{ statuses[statusCode] || statusCode }}
  </span>
</template>
<script>
import orderSetup from '../constants/order'
import safeGet from 'just-safe-get'
export default {
  props: {
    scope: {
      type: Object,
      default: null
    },
    status: {
      type: String,
      default: null
    }
  },
  setup() {
    const orderConst = orderSetup.setup()
    const statuses = {}
    orderConst.statuses
      .concat(orderConst.processingStatusDescriptions)
      .concat(orderConst.transactionStates)
      .forEach((status) => {
        statuses[status.value] = status.label
      })
    return {
      statuses
    }
  },
  computed: {
    isSuccess() {
      return [
        'completed',
        'collected',
        'success',
        'successful',
        'ack',
        'new'
      ].includes(this.statusCode)
    },
    isWarning() {
      return ['not_completed', 'not_collected', 'on_work', 'waiting'].includes(
        this.statusCode
      )
    },
    isError() {
      return [
        'failed',
        'fail_collect',
        'unsuccessful',
        'rejected',
        'rejected_bank',
        'rejected_validation',
        'rejected_risk'
      ].includes(this.statusCode)
    },
    statusCode() {
      // add fail check if both are null to return empty
      if (
        this.status === null &&
        safeGet(this.scope?.row, this.scope?.column?.property, '') === null
      ) {
        return ''
      } else {
        return (
          this.status ||
          safeGet(this.scope?.row, this.scope?.column?.property, '')
        ).toLowerCase()
      }
    }
  }
}
</script>
<style scoped>
.status-text {
  width: fit-content;
  height: fit-content;
}
.success {
  color: var(--color-complete);
  background-color: var(--color-complete-background);
}

.warning {
  color: var(--color-warning);
  background-color: var(--color-warning-background);
}

.error {
  color: var(--color-error);
  background-color: var(--color-error-background);
}
</style>
